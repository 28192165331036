import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { getUserEmail, getUserId } from 'src/app/shared/auth-utils';

interface FeedbackDialogData {
    title: string;
    placeholder: string;
    successMessage: string;
}

@Component({ templateUrl: './feedback.component.html' })
export class FeedbackDialog {
    message = new FormControl('', [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<FeedbackDialog>,
        private api: ApiService,
        private router: Router,
        private snackBar: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogData,
    ) {}

    async saveFeedback() {
        if (this.message.invalid) {
            return;
        }
        await this.api.feedback.sendUserFeedback({
            message: this.message.value,
            context: {
                hostname: window.location.hostname,
                url: this.router.url,
                userEmail: getUserEmail(),
                userId: getUserId(),
            },
        });
        this.snackBar.open(this.data.successMessage);
        this.dialogRef.close();
    }
}
